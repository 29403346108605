import { Col, Row } from "react-bootstrap";
import "./style.css";
import { Card } from "../../components/Card";

import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import ProgressIcon from "../../assets/svg/ProgressIcon";
import RejectIcon from "../../assets/svg/RejectIcon";

import { DetailCard } from "../../components/DetailCard";
import { ColumnsType } from "antd/es/table";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Tag } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import CustomTable from "../../components/Table/Table";
import CustomTableCard from "../../components/Table/TableCard";
import TableHeader from "../../components/TableHeader/TableHeader";
import StarIcon from "../../assets/svg/StarIcon";
import DoneIcon from "../../assets/svg/DoneIcon";
import { LanguageContext } from "../../context/LanguageContext";
import { FormattedMessage } from "react-intl";

const EmployeeDashboard = ({
  setIsRightSidebar,
  isRightSidebar,
  isSkelton,
  setLeftWidth,
  leftWidth,
  selectedPage,
  data,
  tableData,
  rowData,
  count,
  ComplaintCount,
  setListStatus,
  handleSearchChange,
  ListStatus,
  setSelectedEmployee,
  setSelectedRowKey,
  selectedRowKey,
}) => {
  const { locale, setLocale } = useContext(LanguageContext);

  const [isCopyClicked, setIsCopyClicked] = useState(false);
  const [selectedLayout, setSelectedLayout] = useState("table");
  const width = parseInt(leftWidth?.split("%")[0]);
  useEffect(() => {
    if (isCopyClicked) {
      setSelectedLayout("copy");
    } else {
      if (width < 53) {
        setSelectedLayout("card");
      } else {
        setSelectedLayout("table");
      }
    }
  }, [width]);

  const columns = [
    {
      title: isSkelton ? (
        <SkeletonTheme baseColor="#CDCDCD" width={49} height={20}>
          <div>
            <Skeleton />
          </div>
        </SkeletonTheme>
      ) : (
        <FormattedMessage id="Req_no" />
      ),
      dataIndex: "number",
      render: (text) =>
        isSkelton ? (
          <SkeletonTheme baseColor="#CDCDCD" width={49} height={20}>
            <div>
              <Skeleton />
            </div>
          </SkeletonTheme>
        ) : (
          <span
            style={{ fontSize: "16px", fontWeight: "600", color: "#3E6E4B" }}
          >
            {text}
          </span>
        ),
      sorter: (a, b) => a?.number?.length - b?.number?.length,

      sortDirections: ["descend"],
    },
    {
      title: <FormattedMessage id="Service_name" />,
      dataIndex: "name",
      render: (text, record) => {
        return isSkelton ? (
          <SkeletonTheme baseColor="#CDCDCD" width={140} height={20}>
            <div>
              <Skeleton />
            </div>
          </SkeletonTheme>
        ) : (
          <div
            className="avatar-name-container"
            style={{
              display: "flex",
              alignItems: "center",
              wordWrap: "break-word",
            }}
          >
            {/* <Avatar shape="square" size="large" icon={<UserOutlined />} /> */}
            {locale === "en" ? (
              <span>{text}</span>
            ) : (
              <span>{record?.fullData?.service?.name_ar}</span>
            )}
          </div>
        );
      },
      // sorter: (a, b) => {
      //   return (
      //     a?.name?.length - b?.name?.length
      //   );
      // },
      // sortDirections: ["ascend", "descend"],
    },
    {
      title: <FormattedMessage id="Requester_Name" />,
      dataIndex: "position",
      render: (text, record) =>
        isSkelton ? (
          <SkeletonTheme baseColor="#CDCDCD" width={167} height={20}>
            <div>
              <Skeleton />
            </div>
          </SkeletonTheme>
        ) : (
          <span>{text}</span>
        ),
      // sorter: (a, b) => a?.position?.length - b?.position?.length,
      // sortDirections: ["ascend", "descend"],
    },
    {
      title: <FormattedMessage id="Emirates_ID" />,
      dataIndex: "section",
      render: (text, record) =>
        isSkelton ? (
          <SkeletonTheme baseColor="#CDCDCD" width={156} height={20}>
            <div>
              <Skeleton />
            </div>
          </SkeletonTheme>
        ) : (
          <span>{text}</span>
        ),
      // sorter: (a, b) => a?.section?.length - b?.section?.length,
      // sortDirections: ["ascend", "descend"],
    },

    {
      title: <FormattedMessage id="Status" />,
      key: "tags",
      dataIndex: "tags",
      render: (tag) => {
        let tagProps = {
          color: "",
          bgColor: "white",
          borderColor: "transparent",
          textColor: "black",
        };

        if (tag === "REJECTED") {
          tagProps = {
            color: "#C10606",
            bgColor: "#FFF5F5",
            borderColor: "transparent",
            textColor: "#C10606",
          };
        } else if (tag === "PENDING") {
          tagProps = {
            color: "#0D5EAF",
            bgColor: "#7F8286",
            borderColor: "transparent",
            textColor: "#0D20AF",
          };
        } else if (tag === "Done") {
          tagProps = {
            color: "#24AF0D",
            bgColor: "#F2FFEF",
            borderColor: "transparent",
            textColor: "#24AF0D",
          };
        } else if (tag === "IN_PROGRES") {
          tagProps = {
            color: "#E0BE08",
            bgColor: "#FCF9E7",
            borderColor: "transparent",
            textColor: "#E0BE08",
          };
        }

        return isSkelton ? (
          <SkeletonTheme baseColor="#CDCDCD" width={90} height={37}>
            <div>
              <Skeleton />
            </div>
          </SkeletonTheme>
        ) : (
          <Tag
            color={tagProps.color}
            key={tag}
            style={{
              color: tagProps.textColor,
              backgroundColor: tagProps.bgColor,
              borderColor: tagProps.borderColor,
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "90px",
                height: "37px",
                fontFamily: "Alexandria",
              }}
            >
              {tag}
            </span>
          </Tag>
        );
      },
      // sorter: (a, b) => a?.name?.length - b?.name?.length,
      // sortDirections: ["ascend", "descend"],
    },
    {
      title: <MoreOutlined className="MoreOutlined" />,
      dataIndex: "more",
      render: () => <MoreOutlined className="MoreOutlined" />,
    },
  ];

  const complaintColumns = [
    {
      title: isSkelton ? (
        <SkeletonTheme baseColor="#CDCDCD" width={49} height={20}>
          <div>
            <Skeleton />
          </div>
        </SkeletonTheme>
      ) : (
        <FormattedMessage id="Req_no" />
      ),
      dataIndex: "number",
      render: (text) =>
        isSkelton ? (
          <SkeletonTheme baseColor="#CDCDCD" width={49} height={20}>
            <div>
              <Skeleton />
            </div>
          </SkeletonTheme>
        ) : (
          <span
            style={{ fontSize: "16px", fontWeight: "600", color: "#3E6E4B" }}
          >
            {text}
          </span>
        ),
      sorter: (a, b) => a?.number?.length - b?.number?.length,
      sortDirections: ["descend"],
    },
    {
      title: <FormattedMessage id="Type" />,
      dataIndex: "request_type",
      render: (text, record) =>
        isSkelton ? (
          <SkeletonTheme baseColor="#CDCDCD" width={140} height={20}>
            <div>
              <Skeleton />
            </div>
          </SkeletonTheme>
        ) : (
          <div
            className="avatar-name-container"
            style={{
              display: "flex",
              alignItems: "center",
              wordWrap: "break-word",
            }}
          >
            {/* <Avatar shape="square" size="large" icon={<UserOutlined />} /> */}
            <span>{text}</span>
          </div>
        ),

      // sorter: (a, b) => a.name.length - b.name.length,
      // sortDirections: ["descend"],
    },
    {
      title: <FormattedMessage id="Requester_Name" />,
      dataIndex: "customer",
      render: (text, record) =>
        isSkelton ? (
          <SkeletonTheme baseColor="#CDCDCD" width={167} height={20}>
            <div>
              <Skeleton />
            </div>
          </SkeletonTheme>
        ) : (
          <span>{text}</span>
        ),
      // sorter: (a, b) => a.requester_name.length - b.requester_name.length,
      // sortDirections: ["descend"],
    },
    {
      title: <FormattedMessage id="Emirates_ID" />,
      dataIndex: "emiratesid",
      render: (text, record) =>
        isSkelton ? (
          <SkeletonTheme baseColor="#CDCDCD" width={156} height={20}>
            <div>
              <Skeleton />
            </div>
          </SkeletonTheme>
        ) : (
          <span>{text}</span>
        ),
      // sorter: (a, b) => a.emiratesid.length - b.emiratesid.length,
      // sortDirections: ["descend"],
    },

    {
      title: <FormattedMessage id="Status" />,
      dataIndex: "status",
      render: (tag) => {
        let tagProps = {
          color: "",
          bgColor: "white",
          borderColor: "transparent",
          textColor: "black",
        };

        if (tag === "REJECTED") {
          tagProps = {
            color: "#C10606",
            bgColor: "#FFF5F5",
            borderColor: "transparent",
            textColor: "#C10606",
          };
        } else if (tag === "PENDING") {
          tagProps = {
            color: "#0D5EAF",
            bgColor: "#7F8286",
            borderColor: "transparent",
            textColor: "#0D20AF",
          };
        } else if (tag === "Done") {
          tagProps = {
            color: "#24AF0D",
            bgColor: "#F2FFEF",
            borderColor: "transparent",
            textColor: "#24AF0D",
          };
        } else if (tag === "IN_PROGRES") {
          tagProps = {
            color: "#E0BE08",
            bgColor: "#FCF9E7",
            borderColor: "transparent",
            textColor: "#E0BE08",
          };
        }

        return isSkelton ? (
          <SkeletonTheme baseColor="#CDCDCD" width={90} height={37}>
            <div>
              <Skeleton />
            </div>
          </SkeletonTheme>
        ) : (
          <Tag
            color={tagProps.color}
            key={tag}
            style={{
              color: tagProps.textColor,
              backgroundColor: tagProps.bgColor,
              borderColor: tagProps.borderColor,
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "90px",
                height: "37px",
                fontFamily: "Alexandria",
              }}
            >
              {tag}
            </span>
          </Tag>
        );
      },
      // sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ["descend"],
    },
    {
      title: <MoreOutlined className="MoreOutlined" />,
      dataIndex: "more",
      render: () => <MoreOutlined className="MoreOutlined" />,
    },
  ];

  const onRow = (row) => {
    // console.log("first", row);
    setSelectedRowKey(row?.key);
    localStorage.setItem("selectedRowKey", JSON.stringify(row?.key));
    rowData(row);
    setSelectedEmployee(
      row?.fullData?.EmployeeAppointment?.employee_id !== "0"
        ? row?.fullData?.EmployeeAppointment?.employee_id
        : ""
    );
  };

 

  return (
    <>
      <Col className="employee-dashboard-col">
        <Row>
          {selectedPage == "headphone" ? (
            <Col
              xs={12}
              md={6}
              lg={
                width > 53
                  ? selectedPage == "headphone"
                    ? 6
                    : 3
                  : width > 35
                  ? 6
                  : 12
              }
            >
              <Card
                icon={<DoneIcon />}
                title={<FormattedMessage id="Complaints" />}
                direction={locale === "en" ? "ltr" : "rtl"}
                count={ComplaintCount?.count?.COMPLAINT || 0}
                color="var(--success-normal, #24AF0D)"
                isSkelton={isSkelton}
                setListStatus={setListStatus}
                rowData={rowData}
              />
            </Col>
          ) : (
            <Col
              xs={12}
              md={6}
              lg={
                width > 53
                  ? selectedPage == "headphone"
                    ? 6
                    : 3
                  : width > 35
                  ? 6
                  : 12
              }
            >
              <Card
                icon={<StarIcon />}
                title={<FormattedMessage id="Pending" />}
                count={count?.counts?.PENDING || 0}
                color="var(--primary-normal, #6E4C3E)"
                isSkelton={isSkelton}
                setListStatus={setListStatus}
                rowData={rowData}
                direction={locale === "en" ? "ltr" : "rtl"}
              />
            </Col>
          )}
          {selectedPage == "headphone" ? (
            ""
          ) : (
            <Col xs={12} md={6} lg={width > 53 ? 3 : width > 35 ? 6 : 12}>
              <Card
                icon={<ProgressIcon />}
                title={<FormattedMessage id="in_progress" />}
                count={count?.counts?.IN_PROGRES || 0}
                color="var(--warning-normal, #E0BE08)"
                isSkelton={isSkelton}
                setListStatus={setListStatus}
                rowData={rowData}
                direction={locale === "en" ? "ltr" : "rtl"}
              />
            </Col>
          )}
          {selectedPage == "headphone" ? (
            ""
          ) : (
            <Col xs={12} md={6} lg={width > 53 ? 3 : width > 35 ? 6 : 12}>
              <Card
                icon={<RejectIcon />}
                title={<FormattedMessage id="Rejected" />}
                count={count?.counts?.REJECTED || 0}
                color="var(--errors-normal, #C10606)"
                isSkelton={isSkelton}
                setListStatus={setListStatus}
                rowData={rowData}
                direction={locale === "en" ? "ltr" : "rtl"}
              />
            </Col>
          )}
          {selectedPage == "headphone" ? (
            <Col
              xs={12}
              md={6}
              lg={
                width > 53
                  ? selectedPage == "headphone"
                    ? 6
                    : 3
                  : width > 35
                  ? 6
                  : 12
              }
            >
              <Card
                icon={<DoneIcon />}
                count={ComplaintCount?.count?.SUGGESTION || 0}
                isSkelton={isSkelton}
                color="var(--success-normal, #24AF0D)"
                setListStatus={setListStatus}
                rowData={rowData}
                title={<FormattedMessage id="Suggestions" />}
                direction={locale === "en" ? "ltr" : "rtl"}
              />
            </Col>
          ) : (
            <Col
              xs={12}
              md={6}
              lg={
                width > 53
                  ? selectedPage == "headphone"
                    ? 6
                    : 3
                  : width > 35
                  ? 6
                  : 12
              }
            >
              <Card
                icon={<DoneIcon />}
                title={<FormattedMessage id="Done" />}
                count={count?.counts?.APPROVED || 0}
                isSkelton={isSkelton}
                color="var(--success-normal, #24AF0D)"
                setListStatus={setListStatus}
                rowData={rowData}
                direction={locale === "en" ? "ltr" : "rtl"}
              />
            </Col>
          )}
          {selectedPage == "headphone" ? (
            <Col
              xs={12}
              md={6}
              lg={
                width > 53
                  ? selectedPage == "headphone"
                    ? 6
                    : 3
                  : width > 35
                  ? 6
                  : 12
              }
            >
              <Card
                icon={<DoneIcon />}
                count={ComplaintCount?.count?.TECH_SUPPORT || 0}
                isSkelton={isSkelton}
                color="var(--success-normal, #24AF0D)"
                setListStatus={setListStatus}
                rowData={rowData}
                title={<FormattedMessage id="tech_supports" />}
                direction={locale === "en" ? "ltr" : "rtl"}
              />
            </Col>
          ) : (
            <Col xs={12} md={6} lg={width > 53 ? 3 : width > 35 ? 6 : 12}>
              <Card
                icon={<DoneIcon />}
                title={<FormattedMessage id="Appointment" />}
                count={count?.counts?.APPOINTMENT || 0}
                isSkelton={isSkelton}
                color="var(--success-normal, #24AF0D)"
                setListStatus={setListStatus}
                rowData={rowData}
                direction={locale === "en" ? "ltr" : "rtl"}
              />
            </Col>
          )}
          {selectedPage == "headphone" ? (
            ""
          ) : (
            <Col xs={12} md={6} lg={width > 53 ? 3 : width > 35 ? 6 : 12}>
              <Card
                icon={<DoneIcon />}
                title={<FormattedMessage id="assigned_to_me" />}
                count={count?.counts?.AssignedToMe || 0}
                isSkelton={isSkelton}
                color="var(--success-normal, #24AF0D)"
                setListStatus={setListStatus}
                rowData={rowData}
                direction={locale === "en" ? "ltr" : "rtl"}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <TableHeader
              setLeftWidth={setLeftWidth}
              width={width}
              isSkelton={isSkelton}
              setIsRightSidebar={setIsRightSidebar}
              isRightSidebar={isRightSidebar}
              selectedLayout={selectedLayout}
              setSelectedLayout={setSelectedLayout}
              setIsCopyClicked={setIsCopyClicked}
              isCopyClicked={isCopyClicked}
              title={
                selectedPage === "headphone" &&
                (ListStatus === "Pending" ||
                  ListStatus === "PENDING" ||
                  ListStatus === "Complaints") ? (
                  <FormattedMessage id="Complaints" />
                ) : selectedPage === "headphone" &&
                  ListStatus === "Suggestions" ? (
                  <FormattedMessage id="Suggestions" />
                ) : selectedPage === "headphone" &&
                  ListStatus === "tech_supports" ? (
                  <FormattedMessage id="Supports" />
                ) : selectedPage === "dashboard" && ListStatus === "Done" ? (
                  <FormattedMessage id="Approved_Requests" />
                ) : selectedPage === "dashboard" &&
                  ListStatus === "Rejected" ? (
                  <FormattedMessage id="Rejected" />
                ) : selectedPage === "dashboard" &&
                  ListStatus === "in_progress" ? (
                  <FormattedMessage id="in_progress" />
                ) : selectedPage === "dashboard" &&
                  ListStatus === "Appointment" ? (
                  <FormattedMessage id="Appointment" />
                ) : selectedPage === "dashboard" &&
                  ListStatus === "assigned_to_me" ? (
                  <FormattedMessage id="Assigned_to_Me" />
                ) : (
                  <FormattedMessage id="Pending" />
                )
              }
              numberOfResults={tableData?.length}
              handleSearchChange={handleSearchChange}
            />
          </Col>
        </Row>
        <Row className="table-row">
          <Col xs={12} md={12} lg={12}>
            <Row style={{ cursor: "pointer" }}>
              {selectedLayout == "table" ? (
                <CustomTable
                  isSkelton={isSkelton}
                  data={tableData}
                  columns={
                    selectedPage == "dashboard" ? columns : complaintColumns
                  }
                  onRow={
                    selectedPage === "dashboard" || selectedPage == "headphone"
                      ? onRow
                      : null
                  }
                  selectedRowKey={selectedRowKey}
                />
              ) : (
                // <DetailCard width={width} />
                <CustomTableCard
                  isSkelton={isSkelton}
                  data={tableData}
                  columns={
                    selectedPage == "dashboard" ? columns : complaintColumns
                  }
                  onRow={
                    selectedPage === "dashboard" || selectedPage == "headphone"
                      ? onRow
                      : null
                  }
                  selectedRowKey={selectedRowKey}
                  width={width}
                  selectedPage={selectedPage}
                />
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default EmployeeDashboard;
